import autosize from 'autosize/dist/autosize';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/scrollTrigger.js";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import {weGetHeighestElement} from './js-global/we-strap';
import 'parsleyjs';
import 'parsleyjs/src/parsley.css';
import {bp} from'./js-global/variables-constants/breakpoints';

import BentoGrid from "@bentogrid/core";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);


	//AI BODY MODAL FIX
	/* var originalPadding = parseInt($("body").css("padding-right"));

	$("#we-service-request-modal").on("show.bs.modal", function () {
		console.log('we modal opened');
		var scrollbarWidth =
			10;
		$("body").css("padding-right", originalPadding + scrollbarWidth);
	});

	$("#we-service-request-modal").on("hidden.bs.modal", function () {
		console.log("we modal hidden");
		$("body").css("padding-right", originalPadding);
	}); */
	//END AI

$(function () {
	//AUTOSIZE
	autosize($("textarea"));

	$(document).ajaxComplete(function () {
		autosize(document.querySelectorAll("textarea"));
	});

	var servicesOverviewHeight = $(".column__services-overview").height();
	var formStrechTL = gsap.timeline({ repeat: 0, repeatDelay: 1, paused: true });

	//add 3 tweens that will play in direct succession.
	formStrechTL.set(".column__services-overview", {
		height: servicesOverviewHeight,
	});
	formStrechTL.set(".column__services-overview", { opacity: 0 });
	formStrechTL.to(
		".column__services-overview",
		{ duration: 0.5, padding: "0" },
		0
	);
	formStrechTL.to(
		".column__services-overview",
		{ duration: 0.5, width: "0%" },
		0
	);
	formStrechTL.to(
		".column__new-task-form",
		{ duration: 0.5, width: "100%" },
		0
	);
	formStrechTL.to(
		".hero__new-inquiry-wraper",
		{ duration: 0.5, marginLeft: "0" },
		0
	);

	$("#new-task-inquiry-form input, #new-task-inquiry-form textarea")
		.not("button")
		.on("focus", function (e) {
			e.preventDefault();
			var self = $(this);
			servicesOverviewHeight = $(".column__services-overview").height();
			formStrechTL.play();
		});

	$(".hero__new-inquiry-tab").on("click", function (e) {
		e.preventDefault();
		var self = $(this);
		servicesOverviewHeight = $(".column__services-overview").height();
		formStrechTL.play();
		if (self.hasClass("hero__new-inquiry-tab--start-project")) {
			$("#new-task-inquiry-form__title").trigger("focus");
		}
	});
	/* 	$(window).on("scroll", function () {
		formStrechTL.reverse();
	}); */
	/* 	$(window).on("resize", function () {
		formStrechTL.reverse();
	}); */

	$(document).on("mouseup", function (e) {
		var container = $(".column__new-task-form");

		// if the target of the click isn't the container nor a descendant of the container
		if (!container.is(e.target) && container.has(e.target).length === 0) {
			formStrechTL.reverse();
		}
	});

	//SECTION TESTIMONIALS
	function setTestimonialsHeight() {
		var heighestTestimonialHeight = weGetHeighestElement(
			".we-testimonials__slide",
			"height"
		);
		console.log(heighestTestimonialHeight);
		$(".we-testimonials__slide").css("height", heighestTestimonialHeight);
	}

	setTestimonialsHeight();

	$(window).on("resize", function () {
		setTestimonialsHeight();
	});

	$(".we-testimonials__slider").on(
		"breakpoint",
		function (event, slick, direction) {
			setTestimonialsHeight();
		}
	);

	//SECTION ANIMATED SERVICES

	//SINGLE SECTION HOVER
	$(".we-services-animated .we-service").each(function (index, element) {
		// element == this
		let weSingleServiceHoverTL = gsap.timeline({
			repeat: 0,
			repeatDelay: 1,
			paused: true,
		});

		//add 3 tweens that will play in direct succession.
		weSingleServiceHoverTL.to($(element).find(".we-textual"), {
			duration: 0.3,
			ease: "power2.out",
			y: -10,
		});
		weSingleServiceHoverTL.to($(element).find(".we-read-more-link-container"), {
			duration: 0.2,
			ease: "power2.out",
			opacity: 1,
			x: -30,
		});

		$(element).hover(
			function () {
				// over
				weSingleServiceHoverTL.play();
			},
			function () {
				// out
				weSingleServiceHoverTL.reverse();
			}
		);
	});

	//SINGLE SECTION HOVER
	//END SECTION ANIMATED SERVICES

	//MODAL SERVICE REQUEST FORM

	//TRIGGER
	/* $("#we-service-request-modal").on("show.bs.modal", function (event) {
		// do something...
		console.log('test');
	}); */

	/* $(document).on("show.bs.modal", "#we-service-request-modal", function () {
		console.log("test");
	}); */
	//END TRIGGER

	//$("#new-task-inquiry-form__category-list").select2("destroy");

	//MODAL SCROLLBAR GLICH FIX


	//END MODAL SCROLLBAR GLICH FIX

	//END MODAL SERVICE REQUEST FORM
	/* const myBento = new BentoGrid({
		// Add configuration options
		target: ".bentogrid",
		cellGap: 20,
		columns: 12,
		minCellWidth: 100, // Optional: Customize minimum cell width
      cellGap: 10, // Optional: Customize gap between cells
      aspectRatio: 1, // Optional: Customize aspect ratio of cells
      breakpoints: { // Optional: Define breakpoints for responsive behavior
        600: {minCellWidth: 150, cellGap: 20}, // Example breakpoint at 600px
      },
      balanceFillers: true, // Optional: Enable balancing fillers
	}); */
	/* const myOtherBento = new BentoGrid({
		// Add configuration options
		"target": ".bentogrid-2",
		"cellGap": 20,
		"columns": 4,
		
	}); */
});



//BENTO TEST


//MOBILE NAV
/* $(function () {
	
	$(".we-first-level-li.menu-item-has-children > a").on("click", function (e) { 
		e.preventDefault();

		var self = $(this);
		var parent = self.parent();
		var submenu = parent.find(".sub-menu");

		console.log("Sub should open 2");

		var mobileNavSubMenuOpen = gsap.timeline({repeat: 0, repeatDelay: 1});
		//add 3 tweens that will play in direct succession.
		mobileNavSubMenuOpen.set(submenu, {display: "block"});
		mobileNavSubMenuOpen.set(submenu, {height: "0px"});
		mobileNavSubMenuOpen.to(submenu, {duration:0.3, height: "300px"});

		if(!parent.hasClass("we-sub-menu-open")) {
			mobileNavSubMenuOpen.play();
			parent.addClass("we-sub-menu-open");
		} else {
			mobileNavSubMenuOpen.reverse();
			parent.removeClass("we-sub-menu-open");
		}
		
	});
}); */


$(function () {
    $(".we-first-level-li.menu-item-has-children > a").each(function (index, element) {
        $(element).on("click", function (e) { 
			e.preventDefault();
	
			var self = $(this);
			var parent = self.parent();
			var submenu = parent.find(".sub-menu");
	
			console.log("Sub should open 2");
	
			
	
			if(!parent.hasClass("we-sub-menu-open")) {
				parent.addClass("we-sub-menu-open");
				submenu.slideDown(300);
				
			} else {
				parent.removeClass("we-sub-menu-open");
				submenu.slideUp(300);
			}
			
		});
    });

});

//END MOBILE NAV


//LS NAVIGATION
$(document).ready(function() {
    // Match elements with class starting with 'sub-double-'
    $('li.menu-item[class*="sub-double-"]').each(function() {
        var $this = $(this);

        // Extract the number X from class 'sub-double-X'
        var splitNumber = parseInt($this.attr('class').match(/sub-double-(\d+)/)[1], 10);

        if (isNaN(splitNumber)) {
            return; // Skip if the number isn't valid
        }

        var $subMenu = $this.find('.sub-menu:first');

        // Check if there's more items than the split number
        if ($subMenu.children('li').length > splitNumber) {
            // Split the li elements based on splitNumber
            var $rest = $subMenu.children('li:gt(' + (splitNumber - 1) + ')');

            // Create new submenu for the rest of the li elements
            var $secondSubMenu = $('<ul class="sub-menu"></ul>').append($rest);

            // Append the new submenu next to the existing submenu within the same parent
            $subMenu.after($secondSubMenu);
        }
    });

});


//ZAGREBWEB WOOCOMMERCE MY ACCOUNT PAGE 
$(function () {
	$(".woocommerce-MyAccount-navigation-link--edit-address a").text("Adresa");
	$(".woocommerce-MyAccount-navigation-link--customer-logout a").text("Odjava");
	$(".woocommerce-MyAccount-navigation ul li").css("opacity", "1");

	//PROFILE PAGES SECTIONS TITLES

	if ($('table.my_account_subscriptions').length) {
        $('<h2 class="we-my-webinars-dash-title we-mb-20">Moje pretplate</h2>').insertBefore('table.my_account_subscriptions');
    }
	if ($('table.subscription_details').length) {
        $('<h2 class="we-my-webinars-dash-title we-mb-20">Pretplata</h2>').insertBefore('table.subscription_details');
    }
	if ($('form#add_payment_method').length) {
        $('<h2 class="we-my-webinars-dash-title we-mb-20">Dodaj metodu plaćanja</h2>').insertBefore('form#add_payment_method');
    }
	if ($('.woocommerce-MyAccount-paymentMethods').length) {
        $('<h2 class="we-my-webinars-dash-title we-mb-20">Metode plaćanja</h2>').insertBefore('.woocommerce-MyAccount-paymentMethods');
    }

	var $addressFieldsElement = $('.woocommerce-address-fields');
    if ($addressFieldsElement.length) {
        var $previousH3Element = $addressFieldsElement.prev('h3');
        if ($previousH3Element.length) {
            $previousH3Element.addClass('we-my-webinars-dash-title');
        }
    }
});
//END ZAGREBWEB WOOCOMMERCE MY ACCOUNT PAGE 

//ZAGREBWEB WOOCOMMERCE CHECKOUT PAGE
document.addEventListener("DOMContentLoaded", function() {
    // Function to wrap button text in span
    function wrapButtonText(buttons) {
        buttons.forEach(function(button) {
            if (!button.querySelector('span')) {
                var buttonText = button.textContent;
                button.innerHTML = '<span>' + buttonText + '</span>';
            }
        });
    }

    // Select all the buttons that need text wrapped
    function getButtons() {
        return document.querySelectorAll('button[name="apply_coupon"], button[name="woocommerce_checkout_place_order"], button[name="login"]');
    }

    // Initial call in case the buttons are already present
    wrapButtonText(getButtons());

    // Observe changes in the DOM to detect when buttons are added
    var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.addedNodes.length) {
                wrapButtonText(getButtons());
            }
        });
    });

    // Start observing the document body for changes
    observer.observe(document.body, { childList: true, subtree: true });
});

//PARSLEY
$('.woocommerce-form-register, .woocommerce-checkout').parsley();

    // Add Parsley validation to the email field dynamically
    $('#reg_email').attr({
        'required': 'required',
        'data-parsley-type': 'email',
        'data-parsley-required-message': 'Email je obavezan',
        'data-parsley-type-message': 'Unesite važeću email adresu'
    });

//END ZAGREBWEB WOOCOMMERCE CHECKOUT PAGE